import { AlertService } from '@core/services/alert.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { FileSaved } from '@shared/models/files';

@Component({
  selector: 'ic-input-filev2',
  templateUrl: './input-filev2.component.html',
  styleUrls: ['./input-filev2.component.scss'],
})
export class InputFilev2Component implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() titleInputFile: string;
  @Input() textBtnSave: string = 'Guardar';
  @Input() aceptedFileTypes: string = 'text/plain, application/pdf, image/*';
  @Input() id: string = Math.random().toString();
  @Input() files: File[] = [];
  @Input() filesSaved: FileSaved[] = [];
  @Input() $filesSavedEvent: Observable<FileSaved[]>;

  /***Se debe seleccionar si se requiere el boton de guardar o si solamemente activar el boton de change del input file */
  @Output() uploadHandler: EventEmitter<File[]> = new EventEmitter();
  @Output() changeFileHandler: EventEmitter<File[]> = new EventEmitter();
  @Input() handler: 'upload' | 'change' = 'upload';

  constructor(private _alertService: AlertService) {}
  @Input() multipleFile: boolean = false;

  displayUpload = false;

  ngOnInit(): void {
    if (this.$filesSavedEvent) {
      this.subs.add(
        this.$filesSavedEvent.subscribe((data: FileSaved[]) => {
          this.filesSaved = data;
          this.files = [];
        })
      );
    }

    console.log(' -- - ', decodeURIComponent('Tarjeta%20de%20Circulaci%C3%B3n.png,'));
  }

  onFileChange(pFileList: File[]) {
    if (!this.multipleFile && pFileList.length > 1) {
      return this._alertService.error('Solo se permite un archivo');
    }
    this.files = Object.keys(pFileList).map((key) => pFileList[key]);
    this.displayUpload = false;

    this.changeFileHandler.emit(this.files);
  }

  uploadFiles() {
    this.uploadHandler.emit(this.files);
  }

  eventViewImg(url: string) {
    this._alertService.alertViewImg(url);
  }

  removeFile(pFile: File) {
    this.files = this.files.filter((file) => file.name !== pFile.name);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { Component, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';

import SwiperCore, { Lazy, Pagination, Navigation, Keyboard } from 'swiper';

// install Swiper modules
SwiperCore.use([Lazy, Pagination, Navigation, Keyboard]);

import { FileCarousel } from '../../models/files';

@Component({
  selector: 'ic-slide-swpiper',
  templateUrl: './slide-swpiper.component.html',
  styleUrls: ['./slide-swpiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SlideSwpiperComponent {
  @Input() files: FileCarousel[] = [];
  @Input() fullscreen = false;
  @Output() eventClickSlide: EventEmitter<any> = new EventEmitter<any>();
  @Input() modal = true;

  constructor() {}

  onSlideChange() {
    //console.log('slide change');
  }

  eventClickImgEmit() {
    this.eventClickSlide.emit();
  }
}

<ng-container *ngTemplateOutlet="slide_swiper"></ng-container>

<ng-template #slide_swiper>
  <swiper
    #swiper
    style="--swiper-navigation-color: #70be01; --swiper-pagination-color: #70be01"
    [lazy]="true"
    [loop]="true"
    [pagination]="{ clickable: true }"
    [navigation]="true"
    [keyboard]="{ enabled: true }"
    (slideChange)="onSlideChange()"
    class="mySwiper"
    *ngIf="files.length > 0">
    <ng-template swiperSlide *ngFor="let file of files">
      <img
        *ngIf="file.type === 'image'"
        width="450"
        height="450"
        [ngSrc]="file.url"
        [ngClass]="
          fullscreen ? 'h-[18rem] sm:h-[26rem] md:h-[35rem] lg:h-[40rem] xl:h-[50rem]' : 'h-72 sm:h-72 lg:h-80'
        "
        class="swiper-lazy block flex-shrink-0 cursor-pointer object-contain"
        (click)="eventClickImgEmit()"
        priority />

      <video
        *ngIf="file.type === 'video'"
        controls
        [src]="file.url"
        [ngClass]="
          fullscreen ? 'h-[18rem] sm:h-[26rem] md:h-[35rem] lg:h-[40rem] xl:h-[50rem]' : 'h-72 sm:h-72 lg:h-80'
        "></video>

      <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
    </ng-template>
  </swiper>
</ng-template>

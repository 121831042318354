import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-factory-dialog',
  templateUrl: './factory-dialog.component.html',
  styleUrls: ['./factory-dialog.component.scss'],
})
export class FactoryDialogComponent<T> {
  constructor(
    public dialogRef: MatDialogRef<FactoryDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      template: TemplateRef<any>;
      context: T;
    }
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

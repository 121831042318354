import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styleUrls: ['./breadcrums.component.scss'],
})
export class BreadcrumsComponent {
  @Input() seccion: string;

  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { MaterialModule } from './material-design/material.module';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Angular Material
import { MatPaginatorIntl } from '@angular/material/paginator';

//Prime NG
import { PrimeNGModule } from './prime-ng/primeng.module';

//Calendario Full

// Lotti Animacion Logo
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { LayoutModule } from '@angular/cdk/layout';
import { BreadcrumsComponent } from './breadcrums/breadcrums.component';

export function playerFactory() {
  return player;
}

//Tabla --->> tabla reutilizable
import { TableDataComponent } from './components/table-data/table-data.component';
import { DataPropertyGetterPipe } from '../core/pipe/data-property-getter.pipe';
import { PaginationInternationalization } from './components/table-data/pagination.internationalization';

//Factory dialog
import { FactoryDialogComponent } from './components/factory-dialog/factory-dialog.component';
import { ComentariosRecordatoriosComponent } from './common/comentarios-recordatorios/comentarios-recordatorios.component';

//Mask input
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatDateCustomPipe } from '../core/pipe/format-date-custom.pipe';
import { InputFilev2Component } from './components/input-filev2/input-filev2.component';
import { FileDragNDropDirective } from './components/directives/file-drag-n-drop.directive';
import { SwiperModule } from 'swiper/angular';
import { SlideSwpiperComponent } from './components/slide-swpiper/slide-swpiper.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    BreadcrumsComponent,
    TableDataComponent,
    DataPropertyGetterPipe,
    FactoryDialogComponent,
    ComentariosRecordatoriosComponent,
    InputFilev2Component,
    FileDragNDropDirective,
    SlideSwpiperComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    LottieModule.forRoot({
      player: playerFactory,
    }),
    LayoutModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    ReactiveFormsModule,
    PrimeNGModule,
    FormatDateCustomPipe,
    SwiperModule,
    ImageCropperModule,
  ],
  exports: [
    MaterialModule,
    PrimeNGModule,
    CommonModule,
    LottieModule,
    LayoutModule,
    BreadcrumsComponent,
    TableDataComponent,
    InputFilev2Component,
    SlideSwpiperComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginationInternationalization }, provideNgxMask()],
})
export class SharedModule {}
